@import utils/scss/index

.container
  margin-bottom: 20px

  .msg
    background: $primary-10
    padding: 12px 14px
    border-radius: .5rem

    h2, a
      color: #E23636
      font-size: 1rem

    a
      text-decoration: underline

    i
      cursor: pointer
      color: #CC5600
      padding: 6px 10px

    
