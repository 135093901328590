@import utils/scss/index

#Ribbons_Main_Container
  .con-ribbon
    background: $primary-color
    .thebar::-webkit-scrollbar
      display: none

    .ul
      padding: 24px 0
      width: 100%

      li
        display: flex
        align-items: center
        gap: 22px
        margin: 0 18px

        $size: 40px
        img
          width: $size
          height: $size
          border-radius: 100%
        p
          font-weight: 800
          font-size: 18px
          font-style: italic
          line-height: 22px
          color: white
          text-transform: uppercase
          white-space: nowrap
