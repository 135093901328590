@import utils/scss/index

.previous
  color: $primary-color
  cursor: pointer
  width: fit-content

  i
    @include flex()

  h2
    font-size: 1.1rem
    font-weight: 700
    border-bottom: 1px solid
