@import utils/scss/index

.container
  @include flex()
  gap: 10px

  .arrow-btn
    border-color: $secondary-100
    background: $secondary-100
    padding: 10px 14px

    &.disabled
      background: $dark-grey-20
      border-color: $dark-grey-20

  .index
    color: #fff
    display: flex
    gap: 10px

    span
      padding: 10px 18px
      cursor: pointer
      // border: 1px solid 
      border-radius: .25rem
      transition: .25s

      &.active
        color: $secondary-100

      &:hover
        background: $secondary-100
        color: #fff
