@import ../../../utils/scss/index
.title-header
    font-size: 36px
    font-weight: 800
    color: $primary-color
    text-align: center
    margin-top: 6rem
.aboutUsImg
    width: 309px
    img
        object-fit: cover

.aboutUs-info
    p
        font-size: 16px
        font-weight: 500
        color: #3B3D3F
        margin: 1rem 0
.contact-us-con
    padding: 28px 26px 28px 26px
    background: rgb(255,251,242)
    border-radius: 10px
.vision
    font-size: 20px
    font-weight: 700
    color: $primary-color
.subTitle
    font-size: 20px
    font-weight: 700
    color: #CC5600
.form-container2
    background: #FEFEFE
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
    border-radius: 10px
    padding: 28px 26px

    // display: flex
    // justify-content: center
    // align-items: center
    // flex-direction: column
    h1
        font-size: 24px
        font-weight: 700

    p
        font-size: 16px
        font-weight: 500
        display: inline-block
        width: 80%

    input
        background-color: transparent
    textarea
        background-color: transparent
        border: 2px solid #3B3D3F
