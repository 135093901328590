.carousel {
    position: relative;
    width: 100%;
    /* margin: auto; */
    height: 100%;
    overflow: hidden;
}

.carousel-track-wrapper {
    overflow: hidden;
    width: 100%;
}

.carousel-slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-slide {
    box-sizing: border-box;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    border: 1px solid;

    border: 1px solid #BD5A12;
    border-radius: 10px;


}

.carousel-button.left {
    left: 10px;
}

.carousel-button.right {
    right: 10px;
}

.carousel-button svg path {
    fill: #CC5600;

}

.carousel-button:disabled {
    background-color: #D5D5D6;
    cursor: not-allowed;
    border: none;
}

.carousel-button:disabled svg path {
    fill: #FEFEFE;
}

.carousel_indicators {
    display: flex;
    gap: 5px;
    justify-content: center;
    margin-top: 20px;
}

.carousel-indicator {
    width: 14px;
    height: 14px;
    padding: 6px 0px 0px 0px;
    gap: 0px;
    border-radius: 50px 0px 0px 0px;
    opacity: 0px;

    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    position: relative;

}

.carousel-indicator::before {
    content: '';
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 8px;
    height: 8px;
}

.carousel-indicator.active::before {
    background-color: #FFB7001A;

}

.carousel-indicator.active {
    background-color: #e4cf971a;
}