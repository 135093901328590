@import utils/scss/index

.con-section
  background: $secondary-5
  padding: 60px 0

  .form
    max-width: 900px
    width: 90%
    margin: 0 auto 60px
    border: 4px solid $dark-grey-95
    // box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.9)
    background: #FFFBF2
    padding: 20px
    border-radius: .6rem

    .button
      background: $dark-grey-95
      padding: 18px 10px
      border-radius: .4rem
      white-space: nowrap
      text-align: center

      p
        color: #fff
        font-weight: 600

        span
          margin-left: 6px
          text-decoration: underline

    .input-area
      width: 100%

      input
        border: 2px solid $dark-grey-95
        background: #FEFEFE
        padding: 20px 12px
        border-radius: .25rem

  .context
    border: 4px solid $dark-grey-95
    width: 96%
    margin: 0 auto
    border-radius: 1rem
    background: #FEFEFE
    padding: 20px

    .header
      .filter-btn
        max-width: 1000px
        width: 96%
        margin: 0 auto
        span
          overflow-x: auto
          width: 70%

          &::-webkit-scrollbar
            height: 0

        button
          margin-top: 16px

          @media (min-width: 992px)
            margin-top: 0

    .filter-sidebar
      max-width: 1200px
      width: 96%
      margin: 0 auto

      .sidebar-btn
        @include flex()
        gap: 10px
        border: 4px solid $dark-grey-95
        width: fit-content
        border-radius: .4rem
        cursor: pointer
        background: #FEFEFE
        padding: 12px 20px

        h2
          font-size: 1.4rem

    & > div
      h1
        font-size: 1.8rem
        font-weight: 700

  .body
    max-width: 1200px
    width: 96%
    margin: 0 auto

    .con-card
      .cards-a
        display: grid
        gap: 20px
        padding-bottom: 60px

        @media (min-width: 992px)
          grid-template-columns: repeat(3, 1fr)

      .cards-b
        display: grid
        gap: 20px
        padding-bottom: 60px

        @media (min-width: 992px)
          grid-template-columns: repeat(6, 1fr)

        .card
          border: 3px solid #000
          cursor: pointer

          &:hover
            @include box-shadow-2()
            .img
              filter: brightness(90%)

          .header
            display: flex
            align-items: center
            justify-content: space-between
            padding: 0 8px
            background: #000
            color: #fff

            p, i
              font-size: .8rem

            p
              font-family: 'IBM Plex Mono', monospace

          .body
            padding: 10px 12px 8px

            .img
              height: 160px
              border: .5rem solid $secondary-100
              margin-bottom: .6rem
              position: relative
              @media (min-width: 992px)
                height: 120px

            .text
              h1
                border-bottom: 1px solid #333
                width: fit-content
                font-size: 1.2rem

              h1.truncate-e
                display: -webkit-box
                -webkit-box-orient: vertical
                overflow: hidden
                -webkit-line-clamp: 2
                text-overflow: ellipsis
              h3
                font-size: .9rem
                font-weight: 700

              p
                font-weight: 400
                font-size: .8rem
              p.truncate-e
                display: -webkit-box
                -webkit-box-orient: vertical
                overflow: hidden
                -webkit-line-clamp: 1
                text-overflow: ellipsis

      .animated-bg
        background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 10%, #f6f7f8 20%, #f6f7f8 100%)
        background-size: 200% 100%
        animation: bgPos 1s linear infinite
        height: 240px
        border-radius: .6rem

        @keyframes bgPos
          0%
            background-position: 50% 0
          100%
            background-position: -150% 0

        @media (max-width: 992px)
          .main-container
            margin: 0 auto





