@import utils/scss/index

#CreateProject_Main_Container
  margin: 120px auto
  position: relative

.project_modal
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  background-color: rgba(0,0,0,0.4)
  width: 100vw
  height: 100vh

  #modal_component
    position: absolute
    display: flex
    flex-direction: column
    width: 70%
    background-color: white
    margin-top: auto
    top: 50%
    left: 50%
    transform: translate(-40%, -45%)
    @media screen and (max-width: 480px)
      width: 90%
      transform: translate(-50%, -45%)
      .project_details
        display: flex
        flex-direction: column
        gap: 10px
        .left
          width: 100%
          .cover
            img
              width: 100px
              height: 100px
        .right
          width: 100%
    .below_attach
      display: flex
      font-size: 13px
      gap: 5px
      align-items: center
      font-weight: bold
      padding: 7px 20px
      border: 2px solid $primary-color
      color: $primary-color
      border-radius: 15px
    .thumbnail::-webkit-scrollbar
      display: none

    .btn-transparent
      border: 2px solid $primary-color
      padding: 8px 25px
      font-weight: bold
      color: $primary-color
      border-radius: 10px
      background-color: transparent
      &:hover
        color: black
        border-color: black

    .btn-solid
      display: flex
      gap: 10px
      align-items: center
      border: 2px solid $primary-color
      border-radius: 10px
      color: white
      background-color: $primary-color
      padding: 8px 25px

  .project_details
    display: flex
    gap: 4rem
    margin: 1rem 0.5rem

.render
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  border: 3px solid black
  border-radius: 15px
  padding: 40px 10px
  margin: 10px auto

.Create_Project_Card
  margin-top: 30px
  padding: 20px
  box-shadow: 0px 10px 30px 0px #00000026
  border-radius: 20px

  #title
    font-size: 15px

  .below
    margin-top: 20px
    gap: 1.2rem

  .btn-solid
    border: 2px solid $primary-color
    border-radius: 10px
    color: white
    background-color: $primary-color
    padding: 8px 25px
    &:hover
      color: black
      background-color: transparent
      border-color: black

  h3
    font-size: 20px
    margin: 10px 0 15px 0

  #link
    width: 70%
    margin-bottom: 15px
    padding: 6px
    border-radius: 6px

  #related_skill
    display: flex
    gap: 1rem
    margin: 2px 0 20px 0
    overflow-x: scroll

  #related_skill::-webkit-scrollbar
    width: 5px
    height: 3px
  #related_skill::-webkit-scrollbar-thumb
    // background: $dark-grey-97

  #skill
    display: flex
    flex-direction: row
    gap: 1rem
    justify-content: space-between
    align-items: center
    text-align: center
    color: $primary-color
    border: 2px solid $primary-color
    border-radius: 30px
    padding: 10px 24px
    font-size: 15px
    font-weight: bold
    cursor: pointer
    max-width: 50ch
    text-overflow: ellipsis
    white-space: nowrap

  .below_attach
    display: flex
    gap: 8px
    align-items: center
    font-weight: bold
    padding: 7px 20px
    margin: 10px 0 20px 0
    border: 2px solid $primary-color
    color: $primary-color
    border-radius: 15px

  .upload_tag
    color: #000
    font-family: Plus Jakarta Sans
    font-size: 16px
    font-weight: 700
    line-height: 20.16px
    text-align: center
    margin-top: 18px
