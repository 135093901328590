@import utils/scss/index

#Recommended_Jobs_Main_Container

  .con-section.container-01
    padding: 40px 0

    .title, .body, .footer
      padding: 20px

    .con-context
      padding: 0 30px
      .body
        display: grid
        gap: 20px
        padding: 20px 0
        overflow: auto

        @media (min-width: 992px)
          grid-template-columns: repeat(3, 1fr)

      .con-animated-bg
        width: 100%
        display: grid
        gap: 14px
        grid-template-columns: repeat(2, 1fr)

        .animated-bg
          height: 200px
          border-radius: .6rem

        &::-webkit-scrollbar
          height: 0

      .card-job
        transition: .25s
        width: fit-content
        border: 3px solid #2E2E30

        .header
          background: $dark-grey-95
          color: #fff
          padding: 4px 10px 4px 2px

          .box
            $size: 20px
            height: $size
            width: $size

            &.box-1
              background: $primary-color

            &.box-2
              background: #FFB700

            &.box-3
              background: #FEFEFE

        .context
          padding: 10px 16px

          .avatar-img
            $size: 60px
            height: $size
            width: $size

          .class-1
            h2
              font-weight: 600
              font-size: 1.2rem
              white-space: nowrap
              color: $primary-color

              i
                font-style: normal
                background: $primary-color
                color: #fff
                padding: 2px 8px
                border-radius: 1rem
                font-size: .8rem
            h3
              font-weight: 600
              font-size: 1.12rem

            h4
              font-weight: 500
              font-size: 1.12rem
          
          .class-2
            .fa-bookmark
              font-size: 1.4rem
              cursor: pointer

            .arrow-btn
              padding: 10px 14px