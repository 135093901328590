.cards-d {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;

	.card {
		border: 3px solid #000;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		width: 600px;

		&:hover {
			box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.9);

			.img {
				filter: brightness(90%);
			}
		}

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 8px;
			background: #000;
			color: #fff;

			p,
			i {
				font-size: 0.8rem;
			}

			p {
				font-family: 'IBM Plex Mono', monospace;
			}
		}

		.Cardbody {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 5px;
			width: 100%;
			padding: 10px 0px 8px 5px;

			.vertical-border {
				height: 100%;
				border-left: 1px solid #817f7f;
				margin: 0 10px;
			}

			.img {
				height: 120px;
				border: 0.5rem solid #ffb700;
				margin-bottom: 0.6rem;
				position: relative;

				@media (min-width: 992px) {
					height: 120px;
				}
			}

			.text,
			.additional-info,
			.control-form {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 0 10px;

				h1 {
					border-bottom: 1px solid #333;
					width: fit-content;
					font-size: 1.2rem;
				}

				h1.truncate-e {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					overflow: hidden;
					-webkit-line-clamp: 2;
					text-overflow: ellipsis;
				}

				h3 {
					font-size: 0.9rem;
					font-weight: 700;
				}

				p {
					font-weight: 400;
					font-size: 0.8rem;
				}

				p.truncate-e {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					overflow: hidden;
					-webkit-line-clamp: 1;
					text-overflow: ellipsis;
				}
			}

			.additional-info {
				border-left: 1px solid #ddd;
				padding-left: 10px;
			}

			.control-form {
				border-left: 1px solid #ddd;
				padding-left: 10px;
			}
		}
	}
}
