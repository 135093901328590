@import utils/scss/index

#SectionA_Main_Container

  .con-section
    background: white
    padding-top: 30px

    .section-a
      .con-title
        h1
          font-size: 2rem
          font-weight: 800
          white-space: nowrap

        span
          h1:nth-child(2)
            border-bottom: 2.6px solid

          $size: 24px
          img
            width: $size
            height: $size

      .con-form
        padding: 30px 0

        .form
          padding: 18px 12px
          gap: 12px
          border: 1px solid #3B3D3F
          border-radius: 8px
          background: #FFF8E6

          select
            background: url("../../../../../public/img/Vector (1).png") $dark-grey-95 no-repeat 94% !important
            color: #fff
            padding: 14px 30px
            border-radius: .4rem
            font-weight: 600

          input
            padding: 14px 20px
            border: 2px solid #333
            border-radius: .4rem
            background: transparent

          button[type='submit']
            background: $primary-color
            color: #fff
            @include flex()
            width: 50px
            height: 48px
            padding: 15px 16px
            border-radius: 10px

      .con-cta
        & > *
          font-weight: 600
        a
          color: $primary-color
          text-decoration-line: underline

    .section-b
      .img img
        max-width: 500px
        margin: 0 auto
        @media (min-width:1024px)
          // object-fit: cover
          // here
          height: 520px
          object-fit: fill


  @media (min-width: 768px)
    .con-section

      .section-a
        .con-title
          h1
            font-size: 4rem

  @media (min-width: 992px)
    .con-section
      height: 90vh

      .section-a

        .con-form
          .form
            padding: 18px 27px
