.per_hour_input_con {
    background: #F6F6F6;
    padding: 8px 16px;
    border-radius: 10px;
    display: flex;
}

.per_hour_input_con input {
    border: unset !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 30.24px;
    background-color: transparent !important;

}

.per_hour_input_con input:focus {
    padding: unset !important;
    font-size: 18px;

}

.per_hour_input_con input:disabled {
    color: #000 !important;
}

.per_hour_input_con input::placeholder {
    font-size: 18px;

}

.rate_perhour h4 {


    font-size: 18px;
    font-weight: 600;


}

.edit_action_img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.per_hour_input_con select {
    background: none !important;
    width: 40px !important;
    color: #BD5A12 !important;
    cursor: pointer;
}

.badge_empty {
    color: #fdfdff;
    font-size: 10px;
    font-weight: 500;
   


}
.badge_empty_gray {
    color: #8d8d94;
    font-size: 10px;
    font-weight: 500;
   


}

.feedButton div{
    width: 100%;
}
.feedButton div button{
    width: 100%;
}