@import utils/scss/index

.progressBar
  main
    display: flex
    align-items: center
    gap: 10px
 

    .background
      position: relative
      padding: 6px 20px
      background: #fff
      box-shadow: 0px 1px 4px 0px #0C0C0D0D
      box-shadow: 0px 1px 4px 0px #0C0C0D1A

      border-radius: 1rem
      width: 100%

      .bar
        position: absolute
        background: $primary-color
        top: 4px
        left: 6px

        padding: 2px
        border-radius: 1rem

    h2
      font-size: 1.2rem

  .check
    background: #0FAA4E
    color: #fff
    padding: 3px
    border-radius: 50%
    @include flex()
