.con-message
  position: fixed
  z-index: 1001
  top: 30px

  @media(min-width: 992px)
    top: unset
    bottom: 30px
    left: 30px


.message
  width: fit-content
  padding: 14px 24px
  border-radius: .4rem
  background: #ffffff
  box-shadow: 0 2px 6px #b2b1b1

  h1
    font-size: 1.14rem
    font-weight: 600

  i
    padding: 3px 6px
    cursor: pointer
    color: #656464