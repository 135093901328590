@import utils/scss/index

.label
  font-size: 1.2rem
  font-weight: 600

.switch
  position: relative
  display: inline-block
  width: 56px
  height: 26px

  input
    opacity: 0
    cursor: pointer

    &:checked + .slider:before
      -webkit-transform: translateX(26px)
      -ms-transform: translateX(26px)
      transform: translateX(26px)
      background-color: $primary-color

  .slider
    position: absolute
    z-index: -1
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: $dark-grey-10
    border: 1px solid #333
    transition: .4s
    border-radius: 20px

    &::before
      $size: 20px
      position: absolute
      content: ""
      height: $size
      width: $size
      left: 4px
      bottom: 2px
      background: $dark-grey-40
      transition: .4s
      border-radius: 50%
