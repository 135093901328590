@import utils/scss/index

label
  font-weight: bold
  font-size: 15px
  margin-top: 10px
#Text_Component
  padding: 0 10px
h3
  margin-bottom: 20px
  font-size: 20px
  color: $dark-grey-50

textarea
  padding: 7px

.btn-transparent
  display: flex
  gap: 10px
  align-items: center
  border: 2px solid $primary-color
  padding: 8px 25px
  color: $primary-color
  border-radius: 10px
  background-color: transparent
  &:hover
    color: black
    border-color: black

.btn-solid
  display: flex
  gap: 10px
  align-items: center
  border: 2px solid $primary-color
  border-radius: 10px
  color: white
  background-color: $primary-color
  padding: 8px 25px

#Image_Component
  padding: 0 10px

.btn-solid
  display: flex
  gap: 10px
  align-items: center
  border: 2px solid $primary-color
  border-radius: 10px
  color: white
  background-color: $primary-color
  padding: 8px 25px

p
  font-size: 20px
  color: $dark-grey-50

.imagescroll img
  object-fit: contain

.imagescroll::-webkit-scrollbar
  display: none

#Video_Component
  padding: 0 10px
  .uploadedVideo
    display: flex
    flex-direction: column
    max-width: 400px
    border: 2px solid black
    border-radius: 4px
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.8980392157)
    video
      border-radius: unset !important

.btn-solid
  display: flex
  gap: 10px
  align-items: center
  border: 2px solid $primary-color
  border-radius: 10px
  color: white
  background-color: $primary-color
  padding: 8px 25px

p
  font-size: 20px
  color: $dark-grey-50
.imagescroll::-webkit-scrollbar
  display: none

.upload_button
  border: 3px solid #2E2E30
  background: #FFFBF2
  padding: 10px
  box-shadow: 4px 4px 0px 0px #000000E5
.uploaded_img
  display: flex
  flex-direction: column
  align-items: center
  max-width: 400px
  border: 2px solid black
  border-radius: 4px
  box-shadow: 4px 4px 0px 0px #000000E5
.actionsOnUploaded
  width: 100%
  justify-content: center
  background: #8b8985
