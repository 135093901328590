.modal_svg {
    border: 2px dashed #C8C8C8;
    background: #E0E0E033;
    border-radius: 8px;
    padding: 20px;
    width: 336px;
}

.modal_svg button {
    background: transparent !important;
}

.modal_svg button .svg {
    width: unset !important;
}

.modal_svg p {
    font-size: 18px;
    font-weight: 600;
    line-height: 22.68px;
    text-align: left;
    color: #9E9C9C;

}

.add_experience_btn button {
    color: #CC5600 !important;
}

.con-experience-block {
    background: linear-gradient(180deg, #FFFFF1 0%, #f9f6e0 10%);

    height: 200px;
    padding: 20px;
    border-radius: 8px;
    border-bottom: unset !important;
    box-shadow: 0px 1px 2px 0px #0000004D;
}

.experience-block {
    margin-top: 16px;

}

.experience-block .company_name {
    font-size: 16px !important;
    font-weight: bold;
}

.experience-block .job_type {
    background: linear-gradient(180deg, #CC5600 0%, #BD5A12 99%);
    padding: 1px 10px;
    border-radius: 10px;
    font-size: 12px !important;
    white-space: nowrap;
    color: #FDFDFD;
}

.experience-block .company_name {
    font-size: 14px;
    font-weight: 600;
    margin-top: 8px;
}

.experience-block .period {
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;

}

.location_icon {
    height: 15px;
    width: 11px;
}

.school_degree {

    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-transform: uppercase;
    margin-top: 16px;


}

.job_title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    font-size: 20px;
    max-width: 220px;
    font-weight: 700;
}

.actionButtons button {
    border: unset !important;
    padding: unset !important;
}

.experience-block button {
    width: 28px;
}