@import utils/scss/index

#brand-section
  .con-profile
    cursor: pointer

    .con-user-img
      $size-0: 50px
      $size-1: $size-0 - 10px

      .bg
        height: $size-0
        width: $size-0
        background: $secondary-100
        @include flex()

        .img
          height: $size-1
          width: $size-1
          @include flex()
          background: #fff

  .con-notify
    i
      font-size: 1.8rem

  .container-01
    padding: 40px 0

  .section
    border-bottom: 1px solid $dark-grey-95

    &-e, &-f
      border: 0
    .sub-title
      font-size: .8rem
      
    .title
      font-size: 1.36rem
      font-weight: 600

  .first-col
    .outline-wrap
      &:first-child
        padding: 30px 20px

    .section-a
      .con-animated-bg
        display: grid
        gap: 14px

        .animated-bg
          height: 200px
          border-radius: .6rem

      .con-img
        $size: 140px
        height: $size
        width: $size
        background: $secondary-100
        position: relative
        @include flex()

        .upload
          position: absolute
          top: -8px
          right: -8px
          cursor: pointer

          input
            display: none

        .user-img
          @include flex()
          background: #fff
          height: $size - 10px
          width: $size - 10px

          .animated-bg
            width: 100%
            height: 100%

      .user-context
        h1
          font-weight: 600
          font-size: 1.5rem

        h2
          font-weight: 500
          font-size: 16px

    .section-c
      .heading
        p
          font-weight: 600
          color: $primary-color
          text-decoration: underline
          cursor: pointer

      .body
        overflow: auto

        &::-webkit-scrollbar
          height: 0

    .section-d
      .title, .body, .footer
        padding: 20px

      .body
        display: grid
        gap: 20px

        @media (min-width: 992px)
          grid-template-columns: repeat(2, 1fr)

      .footer
        background: $dark-grey-95

      .con-animated-bg
        display: grid
        gap: 14px
        padding: 20px
        grid-template-columns: repeat(2, 1fr)

        .animated-bg
          height: 200px
          border-radius: .6rem


  .second-col
    .outline-wrap
      border: 4px solid #000000
      border-radius: 10px
      padding: 24px
      height: 100%

    .section
      border: 0

      .con-title
        padding-bottom: 20px

        h2
          font-size: 1.2rem
          font-weight: 600

      .section-a
        .con-animated-bg
          display: grid
          gap: 20px

          .animated-bg
            height: 200px
            border-radius: .6rem

      .section-b
        width: fit-content
        margin: 0 auto

      .section-c
        .con-body
          cursor: pointer

          .checkbox
            $size: 18px
            height: $size
            width: $size
            border: 2px solid
            border-radius: .2rem
            @include flex()

            .checker
              height: $size - 8
              width: $size - 8

              &.active
                background: $primary-color

      .section-e, .section-f
        .con-body
          background: $light-grey-40
          border-radius: .6rem

          p
            width: 76%
            padding: 20px
            background: #fff
            text-align: center
            border-radius: .25rem

      .section-f
        h2.link
          font-weight: 600
          font-size: 1.12rem
          color: $primary-color
          text-decoration: underline
          cursor: pointer

        input
          border: 2px solid $dark-grey-95
          width: 200px
          padding: 14px
          border-radius: .25rem
          background: transparent

.referal-modal-body
  img
    $size: 14%
    width: $size
    height: $size

  p
    font-size: 1.4rem
    text-align: center
    width: 260px
