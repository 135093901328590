@import utils/scss/index

#JobFlow_SectionB_Main_Container
  .section
    background: $secondary-5
    padding: 60px 0

    .con-title
      h1
        font-size: 2.8rem
        font-weight: 700
      p
        font-size: 18px
        font-weight: 600
        padding: 2rem 0

      .img
        $size: 30px
        width: $size
        height: $size

    .con-form
      .form
        background: #FFFFFF
        border: none !important
        box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 30px 0px
        border-radius: 1rem
        padding: 30px

        .con-title
          display: flex
          align-items: center
          gap: 14px
          padding: 20px 0

          i
            font-size: 1.2rem

          h2
            font-weight: 600
            font-size: 16px

        .control-form
          textarea
            height: 200px

          .con-warn
            background: $dark-grey-10
            display: inline-flex
            padding: 6px 20px
            border-radius: .4rem
            color: $dark-grey-90

        .con-label
          label
            font-weight: 600
            font-size: 1.2rem

        .con-input
          .entry
            position: relative

            .con-suggest
              position: absolute
              width: 100%
              z-index: 10
              display: none

              &.active
                display: block

              .suggest
                max-height: 300px
                overflow-x: hidden
                overflow-y: auto
                padding: 4px 10px
                background: #fff
                border-radius: .4rem
                box-shadow: 0 0 10px $dark-grey-20

                li
                  padding: 14px

                  &:hover
                    background: $light-grey-40
                    cursor: pointer

                li:not(:last-child)
                  border-bottom: 1px solid $dark-grey-50



          .remove-icon
            color: $primary-color
            font-size: 1.4rem

            i
              cursor: pointer
              padding: 4px 10px

  @media (min-width: 992px)
    .section
      .con-title
        h1
          font-size: 3.6rem
          width: 60%
