@import ../../css/Talent.style

#Onboarding_Talent_SectionE_Main_Component
  .con-header
    .header
      padding: 30px 0

      h2.display
        padding: 20px 0

  .con-form
    .form
      .con-selection-block

        .selection-block
          background: #FEFEFE
          border: 2px solid $dark-grey-95
          max-width: 660px
          width: 100%
          cursor: pointer

          .selector
            padding: 0 20px

          .context
            padding: 26px 0
            .con-icon
              position: relative
              i
                font-size: 2rem
              .dot-bg
                position: absolute
                $position: 18px
                $size: 16px
                top: $position
                left: $position
                height: $size
                width: $size
                border: 2px solid #fff
                background: red
                border-radius: 50%

                &.warn
                  background: #FFB700

                &.valid
                  background: #0FAA4E

            h2
              font-weight: 600
              font-size: 1.6rem
            p
              font-weight: 400
              font-size: 1.4rem

      .radio-button-option
        cursor: pointer
        h2
          font-size: 1.4rem
          font-weight: 400

          input
            width: 100px
            padding: 20px
            border: 2px solid $dark-grey-95
            border-radius: .25rem

        &.section-d
          .radio-button
            $size: 16px
            padding: 4px
            width: $size
            height: $size
            border: 2px solid $dark-grey-95
            border-radius: .25rem
            @include flex()

            .dot
              padding: 4px
              background: #fff
              border-radius: .1rem

              &.active
                background: $primary-color
