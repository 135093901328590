@import utils/scss/index

#JobFlow_SectionA_Main_Container
  .section
    .con-header
      background: $secondary-10
      padding: 100px 0

      .header
        display: flex
        flex-direction: column
        align-items: center
        text-align: center

        h2
          font-size: 3rem
          font-weight: 700

        p
          font-size: 1.2rem
          font-weight: 400

    .con-middle
      .middle
        h2.heading
          font-size: 1.8rem
          font-weight: 700

        .center
          .form
            padding: 18px 12px
            gap: 12px
            border: 1px solid #3B3D3F
            border-radius: 8px
            background: #fff
            box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.9)

            select
              // background: url("../../../../../../../public/img/Vector (1).png") $dark-grey-95 no-repeat 94% !important
              color: #fff
              padding: 14px 30px
              border-radius: .4rem
              font-weight: 600

            input
              padding: 16px 20px
              width: 100%
              border: 2px solid #333
              border-radius: .4rem

              @media (min-width: 992px)
                width: 360px

            button[type='submit']
              background: $primary-color
              color: #fff
              @include flex()
              width: 50px
              height: 48px
              padding: 15px 16px
              border-radius: 10px

        h2.bottom
          font-size: 1.4rem

    .con-context
      .context
        padding: 30px 0 40px 0

        .title
          h1
            font-size: 1.6rem
            font-weight: 600
            color: $dark-grey-95

          h2
            font-size: 1.6rem
            font-weight: 700
            color: #585859

        .con-talent
          display: grid
          grid-template-columns: repeat(2, 1fr)
          gap: 6px

          .talent
            text-align: center
            margin-top: 40px

            .con-box
              $size: 100px
              position: relative
              border: 2px solid $dark-grey-95
              background: $secondary-30
              width: $size
              height: $size
              border-radius: .6rem
              cursor: pointer
              transition: .2s
              @include flex()

              &:hover
                box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.9)

              .img
                $size: 40px
                width: $size
                height: $size

              &.active .con-checkbox
                $distance: 6px
                position: absolute
                border: 2px solid $dark-grey-95
                padding: 2px
                top: $distance
                right: $distance
                border-radius: .25rem

                .checkbox
                  background: $primary-color
                  padding: 4px

            p.caption
              color: $dark-grey-95
              font-weight: 700
              font-size: 1.14rem

      .con-search
        p.title
          font-size: 1.2rem
          font-weight: 600
          color: $dark-grey-80

        .con-input
          max-width: 480px
          width: 100%
          margin: 30px 0

          input
            padding: 18px 20px
            border: 2px solid $dark-grey-95
            border-radius: .25rem

  @media (min-width: 992px)
    .section
      .con-header
        p
          width: 500px

      .con-context
        .context
          .con-talent
            grid-template-columns: repeat(6, 1fr)

            .talent
              text-align: left
