@import utils/scss/index

#Onboarding_Talent_Main_Component
  background: #fff


  .con-header
    .header
      .previous
        padding: 40px 0
      p
        font-weight: 500
        font-size: 1.4rem

      h2.display
        font-weight: 700
        font-size: 36px

  .con-form
    background: #FFFFFF
    box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 30px 0px
    border-radius: 10px

    .form
      padding: 28px 30px

      .con-title
        h2
          font-weight: 600
          font-size: 1.3rem

          @media (min-width: 992px)
            font-size: 18px

      .con-heading
        h2
          font-weight: 500
          font-size: 1.4rem

        p
          font-weight: 400
          font-size: 1.4rem


    