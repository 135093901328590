.feedBackModal-con {
    padding: 7px 18px;
}

.feedBackModal-con h2 {
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.16px;
    letter-spacing: 0.03em;
    text-align: left;
    text-transform: capitalize;

}

input.e_input {
    border-radius: 4px !important;
}

input.e_input:checked {
    background-color:
        #CC5600 !important;
    border-color:
        #CC5600 !important;
}

input.e_input:focus {
    padding: unset !important;
}

.feedBackModal-con label {
    margin-top: unset !important;
}