@import utils/scss/index

#SectionB_Main_Container
  .con-section
    .con-layer
      position: relative
      overflow: hidden

      .con-overlay
        background: $dark-grey-98

      .con-context
        position: absolute
        top: 0
        bottom: 0
        right: 0
        left: 0
        @include flex()

        & > div
          .con-header
            h1
              color: #fff
              font-weight: 700
              font-size: 36px
            p
              font-weight: 500
              font-size: 18px
              color: $dark-grey-35
              padding-top: 20px

          .con-carousel
            margin-top: 30px

            .carousel
              .card-01
                border-radius: .6rem
                margin: 30px 0
                overflow: hidden

                .img
                  width: auto
                  height: 200px

                .context
                  padding: 10px 20px
                  background: #ffffffdc

                  .con-user
                    .user-data
                      h2
                        font-weight: 700
                        font-size: 18px
                        color: #3B3D3F
                      p
                        font-weight: 500
                        font-size: 14px
                        color: #676767

                    .user-dp img
                      $size: 60px
                      width: $size
                      height: $size
                      border-radius: 50%

                  .con-rating
                    p
                      font-weight: 500
                      font-size: 12px
                      color: #676767
                    h2
                      font-weight: 700
                      font-size: 24px
                      color: #0FAA4E

  @media (min-width:768px)

  @media (min-width:992px)
    .con-section
    .con-layer

      .con-overlay

      .con-context
        & > div
          .con-carousel
            .carousel
              .card-01
                width: 400px
                margin: 0 !important
