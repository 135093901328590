@import utils/scss/index

.con-links
  li.dropdown
    position: relative
    &:hover .dropdown-body
      display: block

    .init-dropdown
      cursor: pointer

    .dropdown-body
      position: absolute
      white-space: nowrap
      background: #fff
      padding: 12px 24px
      box-shadow: 8px 8px 0 $dark-grey-95
      border: 2px solid $dark-grey-95
      display: none

      &.active
        display: block

.con-user-img
  $size: 50px

  .bg
    height: $size
    width: $size
    background: $secondary-100
    @include flex()

    .img
      @include flex()
      background: #fff
      height: $size - 10
      width: $size - 10

.con-notify
  font-size: 1.8rem