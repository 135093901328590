@import utils/scss/index

label
 font-weight: bold
 font-size: 15px
 margin-top: 10px
#Text_Component
 padding: 0 10px
 h3
  margin-bottom: 20px
  font-size: 20px
  color: $dark-grey-50

 textarea
  padding: 7px

 .btn-transparent
  display: flex
  gap: 10px
  align-items: center
  border: 2px solid $primary-color
  padding: 8px 25px
  color: $primary-color
  border-radius: 10px
  background-color: transparent
  &:hover
   color: black
   border-color: black
 
 .btn-solid
  display: flex
  gap: 10px
  align-items: center
  border: 2px solid $primary-color
  border-radius: 10px
  color: white
  background-color: $primary-color
  padding: 8px 25px
  

#Image_Component
 padding: 0 10px

 .btn-solid
  display: flex
  gap: 10px
  align-items: center
  border: 2px solid $primary-color
  border-radius: 10px
  color: white
  background-color: $primary-color
  padding: 8px 25px

 p
  font-size: 20px
  color: $dark-grey-50

 .imagescroll img
   object-fit: contain

 .imagescroll::-webkit-scrollbar
  display: none

#Video_Component
 padding: 0 10px

 .btn-solid
  display: flex
  gap: 10px
  align-items: center
  border: 2px solid $primary-color
  border-radius: 10px
  color: white
  background-color: $primary-color
  padding: 8px 25px
  
 p
  font-size: 20px
  color: $dark-grey-50
 .imagescroll::-webkit-scrollbar
  display: none