@import ../../css/Talent.style

#Onboarding_Talent_SectionA_Main_Component

  background-color: #FEFEFE
  .con-header
   .text_intro
    font-size:18px !important
    font-weight:400 !important
   .onboarding_time
    width: 21px
    height: 21px
 
 
  .con-form


    .form-element
      .con-upload
        padding: 40px 0

        .uploads
          border: 2px dashed #000000
          border-radius: 10px
          padding: 100px 0
          cursor: pointer
          @include flex()

          &:hover
            border: 2px dashed #004dff

          input
            display: none

          &.upload-error
            border: 2px dashed #fb0808

      .warn
        font-weight: 400
        font-size: 1.6rem

      .footer
        p
          font-size: 1.4rem

        p:last-child
          text-decoration: underline
          cursor: pointer
  .select_method_con
   box-shadow: unset !important



  