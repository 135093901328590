@import utils/scss/index

.modal-container

  .dialog
    border: 4px solid #2E2E30
    border-radius: 10px
    @media (min-width: 576px)
      max-width: 463px
      margin: 2rem

  .modal-content
    .modal-header
      border: 0

      .modal-title
        font-size: 18px

        p
          color: #484848

    .modal-body
      .con-section

        .section-a,
        .section-b,
        .section-c,
        .section-d
          padding: 0 0 16px 0

        .title
          font-size: 14px
          display: flex
          align-items: center
          gap: 10px
          margin-bottom: 16px

          p
            font-weight: 600
            font-size: 1.14rem
