@import utils/scss/index

#Navbar_Main_Container
  .con-navbar
    height: 10vh
    display: flex
    align-items: center
    position: fixed
    top: 0
    left: 0
    width: 100%
    z-index: 1000
    transition: .25s
    color: $dark-grey-90

    &.fade
      opacity: .8
      background: #fff !important

    .navbar
      .con-center
        .center
          font-weight: 700
          font-size: 1.1rem

      .con-menu
        .menu
          color: $dark-grey-80
          border: 1px solid $dark-grey-30
          padding: 10px 12px 8px 12px
          border-radius: .35rem
          @include flex()

          i
            font-size: 1.3rem

  .con-sidebar
    .sidebar
      position: fixed
      top: 10vh
      left: 0
      bottom: 0
      width: 70%
      height: 100vh
      display: none
      background: $dark-grey-97
      color: #fff
      z-index: 100

      &.active
        animation: slideIn .4s linear
        display: flex

      &.slide-out
        animation: slideOut .4s linear
        display: flex
        left: -100%

  @keyframes slideIn
    0%
      left: -100%
    50%
      left: -50%

    100%
      left: 0

  @keyframes slideOut
    0%
      left: 0
    50%
      left: -50%

    100%
      left: -100%

  @media (min-width: 992px)
