@import utils/scss/index

.header
  max-width: 100%
  width: 600px
  margin: 0 auto 40px auto

.con-form
  .title
    text-align: center
    font-size: 1.4rem
    font-weight: 700
    padding-bottom: 30px

.uploader
  border: 3px solid #2E2E30
  width: 100%
  @media (min-width: 992px)
    width: fit-content

  .context
    width: 200px
    padding: 0 10px

    h2
      font-size: .9rem
      font-weight: 700

    p
      font-size: .8rem

      &:first-child
        font-weight: 600

  .close
    background: $dark-grey-95
    padding: 14px
    border-left: 3px solid #2E2E30

    .btn
      color: #fff
      cursor: pointer
      padding: 8px 16px
      border-radius: .25rem
      background: $primary-color

.container
  padding: 0 20px 30px

.container-3
  .con-section
    .section
      border-top: 1px solid #888
      padding: 14px 20px

      h1.heading
        font-size: 1.2rem
        font-weight: 600
        padding-bottom: 20px

      .user-section
        .user-img
          .bg
            $size: 120px
            background: $secondary-100
            width: $size
            height: $size
            @include flex()

            .img
              width: $size - 10
              height: $size - 10

        .user-info
          h1
            font-size: 1.2rem
          h2
            font-size: 1rem
          p, i
            font-size: .78rem



      p.title-tag
        font-size: .9rem
        font-weight: 400

      h1.body-tag
        font-weight: 600
        font-size: 1.12rem

.selector
  h2
    font-size: 1rem
    font-weight: 400

