@import utils/scss/index

.modal-job-preview
  .section-b .header,
  .section-c .header,
  .section-d .header
    h2
      @include font-block(600, 1.14rem)

  .section-a
    .header
      padding: 10px 20px

      .body
        & > *
          margin-bottom: .4rem
        h2
          @include font-block(600, 1.2rem)

        h3
          @include font-block(400, 1rem)

        p
          @include font-block(400, .8rem)

        p:last-child
          @include font-block(400, .84rem)
          text-decoration: underline

      .con-button
        button
          padding: 10px 16px
          border-radius: 5px

          &:hover
            border: 1px solid $trns

          &:hover > *
            color: #fff

    .social
      border: 1px solid #333
      padding: 16px 12px
      p
        font-family: 'IBM Plex Mono', monospace
        text-transform: uppercase
        text-align: center
        font-weight: 400
        font-size: .8rem

      i
        font-size: 1.6rem
        color: #4d4c4c

  .section-b
    .body
      padding: 10px 0

      @media (min-width: 992px)
        padding: 10px

      p
        @include font-block(400, 1.12rem)

  .section-c
    .body
      h2
        @include font-block(700, 1.8rem, $primary-color)

  .section-b, .section-c, .footer
    padding: 10px 20px

  .footer
    padding-bottom: 40px

.prompt-modal
  padding: 20px
  text-align: center

  .header
    h1
      font-weight: 600
      font-size: 2rem
