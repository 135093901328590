.skills_img_con {
    width: 18px;
    height: 18px;



}

.skills_text {
    font-size: 12px;
    font-weight: 700;
    line-height: 15.12px;

}

.skills_list {

    font-size: 14px;
    font-weight: 400;
    line-height: 21px;


}

.location_img_con {
    width: 8px;

}
.con-experience-block{
    min-height: 200px;
    height: fit-content;
}
.location_text{
  
    font-size: 12px;
    font-weight: 400;
    line-height: 12.6px;

}

