.small_advert,
.medium_advert {
    width: 100%;
    height: 64px;
    overflow: hidden;
    margin: 20px 0;
    border-radius: 10px;
    display: inline-block;
}

.small_advert img,
.medium_advert img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.medium_advert {
    height: 192px;
}

.bg_advert {
    display: inline-block;
    width: 100%;
    height: 297px;
    border-radius: 10px;
    overflow: hidden;
}

.bg_advert img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.view_more {
    color: #BD5A12;
    font-weight: 700;
    font-size: 16px;
}

.black_header {
    background: #2E2E30;
    color: #fff;
    padding: 4px 10px;
}

.black_header .box {
    width: 18px;
    height: 18px;
    background: linear-gradient(180deg, #CC5600 0%, #BD5A12 99%);


}

.box:nth-child(2) {
    background: #FFB700;

}

.box:nth-child(3) {
    background: #fff;

}

.colored_card .context {
    background: #FCF6F2 !important;

}

.premium_user {
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-weight: 800;
    line-height: 12.6px;
    letter-spacing: 1em;
    text-align: center;
    text-shadow: 0px 1px 3px 1px #00000026;
    color: #fff;
    text-transform: uppercase;

}

.PhoneInputCountry {
    border: 2px solid #3B3D3F;
    padding: 0px 8px;
    border-right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-right: unset !important;
}

.premium_perk {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    color: #CC5600;
}

.referral_title {
    font-size: 18px;
    font-weight: 700;


}

.referrals_img {
    width: 77px;
    height: 77px;


}

.referral_bg {
    background: #F6F6F6;
}

.referral_bg {
    background: #F6F6F6;
    padding: 20px 16px;
    border-radius: 10px;

}

.referral_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;

}

.search_talent {
    padding: 0px 6px;
    gap: 10px;
    border-radius: 4px;
    border: 2px solid black;
    height: 60px;

}

.search_talent input {
    border: none;
    height: 100%;
    width: 100%;
    background-color: transparent !important;

}

.search_talent input::placeholder {
    color: #969697;
}