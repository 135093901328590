@import utils/scss/index
$size: 20px

#Circle_Main_Container
  .con-circle
    margin-top: 40%
    position: relative
    cursor: pointer
    & i
      position: absolute
      top: 0
      bottom: 0
      right: 0
      left: 0
      @include flex()
      text-align: center

    .circle
      margin: 0 auto
      padding: 24px
      border-radius: 50%
      border: 3px dashed $primary-color
      height: $size
      width: $size
      @include flex()
      animation: animateRotate 10s linear infinite

      @keyframes animateRotate
        0%
          transform: rotate(0deg)

        50%
          transform: rotate(-180deg)

        100%
          transform: rotate(-360deg)
