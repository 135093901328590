@import utils/scss/index

#Verify_Account_Main_Container
  background: $secondary-5

  .con-context

    .con-header
      padding: 20px 0
      text-align: center

      h2
        font-size: 1.8rem
        font-weight: 700

        @media (min-width:992px)
          font-size: 3rem

    .con-body
      padding: 60px 0 80px 0

      h2
        font-weight: 700
        font-size: 1.6rem

      p
        max-width: 400px
        width: 94%
        margin: 0 auto
        text-align: center
