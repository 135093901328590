@import utils/scss/index

#Tab_Main_Container
  margin-right: 10px

  button.tab
    padding: 8px 24px
    background: $primary-color
    border-radius: 10px
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    transition: .25s
    gap: 10px
    border: 1px solid $trns
    white-space: nowrap

    & > *
      font-weight: 700
      font-size: 16px
      transition: .25s
      color: $button-font-color

    &.active-tab
      background: $primary-color !important
      border: 1px solid $trns !important
      & > *
        color: $button-font-color !important

    &.outline-tab
      &.dark-tab
      @include tab()

    &.primary-outline-tab
      @include tab($brd-cl:$primary-color, $fnt-cl:$primary-color)
      &:hover
        @include tab($dark-grey-95, $fnt-cl: $button-font-color)

    &.dark-tab
      @include tab($dark-grey-95, $fnt-cl: $button-font-color)

    &.outline-fade-tab
      @include tab($brd-cl:$dark-grey-30,$fnt-cl: #ABABAC)

      &:hover
        @include tab(inherit)

    &.fade-tab
      @include tab($dark-grey-20, $light-grey-20, $light-grey-20)
