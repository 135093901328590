.success_card {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    border-radius: 3px;
    background-color: #fff;
    width: 492px;
    padding: 16px 32px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    
}

.e_img-con {
    width: 30px;
    height: 50px;
}

.success_card h4 {
    font-size: 20px;
    font-weight: bold;
    color: #282828;
    display: block;
    margin: 0;
    text-align: center;
}