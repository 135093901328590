.select_box {
    min-width: 306px !important;
    /* padding: 8px 20px; */
    cursor: pointer;
}

.radio-input:checked+.radio-label {
    border: 4px solid #FFD466;
    border-radius: 8px;
}

.select_box .body .img {
    height: 240px !important;
    padding: 10px;
}