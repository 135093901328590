@import utils/scss/index

#Project_Main_Container
  .con-section.container-01
    padding: 40px 0

    .title, .body, .footer
      padding: 20px

    .con-context
      .title
        font-weight: 600
        font-size: 1.6rem

      .body
        display: grid
        gap: 20px

        @media (min-width:992px)
          grid-template-columns: repeat(3, 1fr)

    .footer
      background: $dark-grey-95
