.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  transition: background-color 0.2s, border-color 0.2s;
  color: #fff;
  position: relative;
}


.radio-input {
  position: absolute;
  opacity: 0;
}



.radio-inner-circle {
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 3px solid #14566E;
  border-radius: 50%;
  margin-right: 0.5em;
  transition: border-color 0.2s;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;

}




.radio-input:checked+.radio-label .radio-inner-circle::after {
  content: '';
  display: block;
  width: 0.8em;
  height: 0.8em;
  background-color: #14566E;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}