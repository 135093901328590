@import utils/scss/index

#Project_Details_Main_Container
  .con-section.container-01
    padding: 40px 0

    .con-header
      h1
        font-weight: 700
        font-size: 1.6rem

        @media (min-width: 992px)
          font-size: 2rem

    .con-context
      padding: 12px

      @media (min-width: 992px)
        padding: 24px

      .con-body
        .outline-wrap
          padding: 10px
          .con-img::-webkit-scrollbar
            display: none

          @media (min-width: 992px)
            padding: 20px

          .text-body
            p
              font-size: 1.14rem

          .con-selector
            padding: 24px 0
            div>h2
              font-size: 1.2rem
