.e_radio-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.e_radio-input * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.e_radio-input label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    padding: 0px 20px;
    width: fit-content;
    cursor: pointer;
    height: 124px;
    position: relative;
    border: 2px solid #2E2E30;
    min-width: 600px;
}

.e_radio-input label::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 220px;
    height: 45px;
    z-index: -1;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    border-radius: 10px;
    border: 2px solid transparent;
}

.e_radio-input label:hover::before {
    transition: all 0.2s ease;
    background-color: #2a2e3c;
}

.e_radio-input .label:has(input:checked)::before {


    height: 50px;
}

.e_radio-input .label .text {
    color: #fff;
}

.e_radio-input .label input[type="radio"] {
    border: 2px solid #3B3D3F;
    appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.e_radio-input .label input:focus {
    padding: unset !important;
}

.e_radio-input .label input[type="radio"]:checked {
    background-color: #BD5A12;
    border: none;
    -webkit-animation: puls 0.7s forwards;
    animation: pulse 0.7s forwards;
}

.e_radio-input .label input[type="radio"]:before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: #fff;
    transform: scale(0);
}

.e_radio-input .label input[type="radio"]:checked::before {
    transform: scale(1);
}

.context h2 {

    font-size: 18px;
    font-weight: 600;
    line-height: 22.68px;


}

.context p {

    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;


}

.notice_input {
    width: 99px;
    height: 99px;
}

.text-error {
    color: red;
}


@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }

    70% {
        box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.period_input label {
    height: fit-content !important;
    border: none !important;
}

.period_input label p {

    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;


}

.notice_input {
    border-radius: 4px;
    border: 2px solid #585859;
    height: 60px;
    text-align: center;
    width: 88px;
}

@media (max-width:443px) {
    .e_radio-input label {
        min-width: 100%;
        height: 100%;
    }
}