@import utils/scss/index

#LoginForm_Main_Container
  .con-underline
    margin-bottom: 30px

    div
      border-top: 1px solid
      width: 100%
    h2
      font-weight: 700
      font-size: 16px

  .con-socials
    button.button
      width: 100%
      padding: 14px 30px
      margin-bottom: 18px

    $size: 20px
    img
      width: $size
      height: $size

  #SubForm_Form_Container
    .con-header
      .header_text
       font-size: 48px
       font-weight: 700


      h1
        font-weight: 700
        font-size: 1.6rem
        flex:1
       
      .line
        width:100%
        height:1px
        background:#000
        flex:1

      i.link
        text-decoration: underline
        font-style: normal
