@import utils/scss/index

#Recruiter_Dashboard_Main_Container
  .con-section
    padding: 60px 0

    .section
      margin-bottom: 50px

      .outline-wrap
        padding: 20px

        @media (min-width: 992px)
          padding: 26px

      .title
        h2
          font-weight: 600
          font-size: 1.2rem

        .link > *
          color: $primary-color
          font-weight: 700
          font-size: 1.12rem
          cursor: pointer
          text-decoration: underline

        & p
          text-decoration: underline

    .section-a
      $wrapper-height: 100%

      .first-col

        .outline-wrap
          height: $wrapper-height
        p
          font-size: 1.12rem

        .context
          padding-top: 30px

          h1
            color: $primary-color
            font-weight: 700
            font-size: 3.4rem
            margin-bottom: 10px

          h2
            font-weight: 700
            text-decoration: underline
            font-size: 1rem
            @media (min-width: 992px)
              font-size: 1.2rem

          .cutter
            border: 1px solid #b1aeae
            height: 76px

      .second-col

        .profile-section
          height: $wrapper-height

        .animated-bg
          height: 200px
          border-radius: .4rem

        .con-right

          .right-a
            height: $wrapper-height
            display: flex
            align-items: center
            justify-content: center

            @media (min-width: 992px)
              border-right: 1px solid $dark-grey-95

            .con-img
              $size: 140px
              height: $size
              width: $size
              background: $secondary-100
              position: relative
              @include flex()

              .upload
                position: absolute
                top: -8px
                right: -8px
                cursor: pointer

                input
                  display: none

              .user-img
                @include flex()
                background: #fff
                height: $size - 10px
                width: $size - 10px

                .animated-bg
                  width: 100%
                  height: 100%

            .data
              text-align: center
              @media(min-width: 992px)
                text-align: left

              h2
                font-weight: 600
                font-size: 1.4rem

              h3
                font-weight: 500
                font-size: 1.2rem
                line-height: 2

              p
                font-size: 1.2rem

        .con-left
          .left

            .context
              line-height: 2.4

              h2
                font-size: 1.28rem
                font-weight: 600

            .cta
              color: $primary-color
              text-decoration: underline
              font-weight: 600
              font-size: 1.14rem
              cursor: pointer

    .section-b
      margin: 40px 0
      padding: 20px
      background-color: #FFFBF2

      @media (min-width: 992px)
        @include flex()
        gap: 10px
        justify-content: space-between

      .con-select
        select
          width: 100%
          background: url("../../../../../public/img/Vector (1).png") $dark-grey-95 no-repeat 94% !important
          color: #fff
          padding: 18px 30px 18px 14px
          border-radius: 0.4rem
          font-weight: 600

          @media (min-width: 768px)
            width: fit-content

      .con-input
        width: 100%

        input
          padding: 20px
          border: 2px solid #333
          border-radius: .25rem

      .con-submit
        justify-content: flex-end

    .section-c, .section-d
      border-radius: 0.4rem
      .context
        width: 100%
        display: grid
        gap: 14px
        grid-template-columns: 1fr

        @media(min-width: 992px)
          grid-template-columns: repeat(3, 1fr)

        .animated-bg
          height: 200px
          border-radius: .6rem

    .section-d
      .outline-wrap
        padding: 0

        .title
          padding: 20px 0 0 20px
          h2
            padding: 4px 10px
            border-radius: 1rem
            color: #828283
            cursor: pointer
            transition: .25s
            width: fit-content

            &.active
              background: $primary-10
              color: $primary-color

            &:hover
              background: $primary-10
              color: $primary-color

        .context
          padding: 0 20px 20px

        .footer
          background: $dark-grey-95
          margin: 0
          padding: 16px 0
