@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap')

.skiptranslate
  display: none
.VIpgJd-ZVi9od-ORHb
  display: none
*
  list-style: none

body
  font-family: 'Plus Jakarta Sans', sans-serif
  line-height: 1.5

  #root
    position: relative

img
  width: 100%
  height: 100%
  object-fit: cover

input
  width: 100%

.main-container
  max-width: 100%

a
  color: inherit
  text-decoration: none !important

a:hover
  color: inherit

ul
  padding: 0

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, dl, ol, ul, p
  margin: 0

button, select
  outline: none
  border: 0
  background: unset

.animated-bg
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 10%, #f6f7f8 20%, #f6f7f8 100%)
  background-size: 200% 100%
  animation: bgPos 1s linear infinite

.animated-bg-text
  border-radius: 20px
  display: inline-block
  margin: 0
  height: 20px
  width: 100%
.head_text_specialization
 font-size: 48px
 font-weight: 700
 line-height: 60.48px


.svg
  width: 20px
#brand-section 
    background-color: #FEFEFE


@keyframes bgPos
  0%
    background-position: 50% 0
  100%
    background-position: -150% 0

@media (max-width: 992px)
  .main-container
    margin: 0 auto
