@import utils/scss/index
@import views/dashboard/recruiter/job-flow/section-b/css/SectionB.style.sass

#JobFlow_SectionC_Main_Container
  @extend #JobFlow_SectionB_Main_Container

  .section
    .con-title
      h1
        width: 100%

  .section-d
    .header
      h1
        font-size: 2rem
        font-weight: 700

        @media (min-width: 992px)
          font-size: 3rem

    .body
      .img
        $size: 160px
        width: $size
        height: $size

      p
        font-size: 1.4rem
        text-align: center

        @media (min-width: 992px)
          width: 600px

#target-opportunity-modal
  .con-modal-body
    .section-a
      .header
        padding: 20px

    .section-b, .section-c, .footer
      padding: 20px

    .footer
      padding-bottom: 40px

    @import 'views/_shared/css/post-modal/post-modal.style.sass'
