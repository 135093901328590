@import utils/scss/index

.container
  display: flex
  align-items: center
  gap: 20px

  .circle
    $size: 12px
    width: $size
    height: $size
    background: #858585
    border-radius: 50%
    cursor: pointer

    &.active
      background: $secondary-100
      border: 8px solid #ffffffbf
      width: 24px
      height: 24px
