.con-main
  .title
    font-size: 1.6rem
    font-weight: 700

  .img
    display: block

  p.body
    font-size: 1.4rem
    font-weight: 600
    color: red