@import utils/scss/index

.container
  border: 3px solid $dark-grey-95

  .header
    background: $dark-grey-95
    color: #fff
    padding: 0 4px
    white-space: nowrap
    padding-bottom: 4px
    h2
      font-size: 1rem

    i
      cursor: pointer

  .body
    position: relative
    z-index: 10

    .img
      height: 140px

    .arrow-btn
      position: absolute
      border-radius: .4rem
      right: 10px
      bottom: 10px
      padding: 7px 13px
      border: 1px solid $primary-color
