#Onboarding_Talent_SectionF_Main_Component {
    background-color: #FEFEFE;
}

.select_method_con {
    box-shadow: unset !important;
}


.job_categories_header {
    font-size: 14px;
    font-weight: 400;
    color: #969697;

}

.job_list {
    color: #585859;
    font-size: 14px;
    font-weight: 500;

}

.job_list:hover {
    color: #CC5600;
}

.activeJob {
    color: #CC5600;
}

.job_grid {
    display: flex;
    align-items: start;
    gap: 6rem;
}

.job_subCategories {
    background: #F8F8F8;
    height: 95%;

}


.check_field-checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #FF9D0B80;
    border-radius: 5px;
    background-color: transparent;
    display: inline-block;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
}

.check_field-checkbox:before {
    content: "";
    background-color: #BD5A12;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 10px;
    height: 10px;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
}

.check_field-checkbox:checked:before {
    transform: translate(-50%, -50%);

}

.check_field-checkbox-label {


    font-size: 14px;
    font-weight: 500;
    line-height: 17.64px;
    text-align: left;
    color: #585859;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
}

.check_field-checkbox {
    padding: unset !important;
}

.selectedCategory {
    background-color: #D8E8F4;
    border: 2px solid #4CAF50;
}