@import utils/scss/index

.modalBody
 
  .header
    h2
      font-weight: 800
      font-size: 2.4rem
  .img
    $size: 160px
    height: $size
    width: $size

  .text
    font-size: 1.2rem

  .reverse
    color: $primary-color
    font-weight: 700
    font-size: 1.2rem
    text-decoration: underline
    cursor: pointer
