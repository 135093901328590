.cer-main
  padding: 20px 0 40px 0

  h2
    font-weight: 700
    font-size: 2rem

  label
    font-size: 1.14rem
    padding-bottom: 10px

  span
    color: red
    font-weight: bold
  
  button
    background: black
    color: white
    padding: 15px 25px
    border-radius: 10px
    font-weight: bold

  .modal-body
    #form-body
      margin: 10px 0  

