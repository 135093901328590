@import utils/scss/index

#Auth_Main_Container
  .con-section
    $margin: 60px
    margin-top: $margin
    margin-bottom: $margin

    .con-context
      .con-title
        h1
          font-weight: 700
          font-size: 3rem
          color: #000
        p
          font-weight: 400
          font-size: 1.2rem

  @media (min-width: 992px)
    .con-section
      .con-context

        $size: 450px
        .img
          width: $size
          height: $size
