@import utils/scss/index

.section
  background: #FCF6F2
  display: flex
  flex-direction: column
  align-items: center
  border-radius: 10px
  padding: 26px 0
  margin: 0 auto

  .top
    font-size: 1.2rem
    font-weight: 600

  .center
    margin: 10px 0

  .con-circle
    $size: 120px
    $deg: 210deg
    width: $size
    height: $size
    padding: $size / 1.8
    background: #fefcff
    border-radius: 50%
    position: relative
    @include flex()

    .circle
      @include flex()

      .mask, .fill
        width: $size
        height: $size
        position: absolute
        border-radius: 50%

      .mask, .mask.fill
        background-color: $primary-color
        clip: rect(0px, $size / 2, $size, 0px)

      .mask.full, .fill
        // transform: rotate($deg)

      .inside-circle
        $--size: $size - 14
        width: $--size
        height: $--size
        position: absolute
        @include flex()
        border-radius: 50%
        background: #fff
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        font-weight: 600
        font-size: 1.4rem
        z-index: 10

  .bottom
    background: #fff
    border-radius: .4rem
    padding: 12px
    @include flex()
    gap: 14px

    span
      display: block
      $size: 20px
      width: $size
      height: $size
      border-radius: .25rem
      background: $primary-color

    p
      font-size: 1.2rem
