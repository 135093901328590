@import utils/scss/index

#SectionD_Main_Container
  $fnt-wgt-8: 800
  padding: 30px 0
  background: #FFF8E6
  .con-section
    .con-right
      max-width: 500px
      width: 100%

      h2
        font-weight: $fnt-wgt-8
        font-size: 1.3rem
        color: #272727
      h1
        font-weight: $fnt-wgt-8
        font-size: 3rem
        text-transform: uppercase
        color: $primary-color
        margin: 8px 0 30px 0
      p
        font-weight: 500
        font-size: 1.2rem
        color: #3B3D3F
