@import utils/scss/index

#JobFlow_SectionB_Main_Container
  .section
    background: $secondary-5
    padding: 60px 0

    .con-title
      h1
        font-size: 2.8rem
        font-weight: 700
      p
        font-size: 1.4rem
        font-weight: 600
        padding: 2rem 0

      .img
        $size: 30px
        width: $size
        height: $size

    .con-form
      .form
        background: #FFFFFF
        border: 4px solid #000000
        box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.9)
        border-radius: 1rem
        padding: 30px

        .con-title
          display: flex
          align-items: center
          gap: 14px
          padding: 20px 0

          i
            font-size: 1.2rem

          h2
            font-weight: 600
            font-size: 1.6rem

        .control-form
          textarea
            height: 200px

          .con-warn
            background: $dark-grey-10
            display: inline-flex
            padding: 6px 20px
            border-radius: .4rem
            color: $dark-grey-90

        .con-label
          label
            font-weight: 600
            font-size: 1.2rem

  @media (min-width: 992px)
    .section
      .con-title
        h1
          font-size: 3.6rem
          width: 60%
