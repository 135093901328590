@import utils/scss/index

.con-main
  h2.title
    font-size: 1.4rem
    font-weight: 600

  p
    font-size: 1.2rem
    font-weight: 500
    text-align: center