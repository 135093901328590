@import utils/scss/index

#Recruiter_Profile_Main_Container
  background: $secondary-5
  padding-bottom: 80px

  .con-header
    .header
      h2
        font-size: 3rem
        font-weight: 700

      p
        font-size: 1.2rem
        font-weight: 600

  .con-form
    .form
      background: #FFFFFF
      border: 4px solid #000000
      border-radius: 1rem
      padding: 30px
      @include box-shadow-1()

      .con-title
        display: flex
        align-items: center
        gap: 14px
        padding: 20px 0

        i
          font-size: 1.2rem

        h2
          font-weight: 600
          font-size: 1.4rem
