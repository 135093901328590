.e_form {
  background-color: #fff;
  display: block;
  padding: 1rem;
  max-width: 430px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
  border-radius: 3px;
  background-color: #fff;
  width: 392px;
  padding: 60px 32px;
  margin-top: 30px;

}

.e_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  gap: 10px;
  background: #CC5600;
  border-radius: 10px;
  transition: 0.5s;
  border: 1px solid transparent;
  color: #ffffff;
}

.e_input-container input {
  width: 100%;
}

.e_form button {
  width: 100% !important;
}

.logo_symbol {
  position: absolute;
  top: 50px;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #CC5600;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.logo_symbol .e_img-con {
  width: 30px;
  height: 50px;
}

.e_form-title {
  font-size: 20px;
  font-weight: bold;
  color: #282828;
  display: block;
  margin: 10px 0;
  text-align: center;

}

.calm_text {
  /* margin: 16px 0 0; */
  line-height: 1.71;
  color: #808080;
  text-align: start;
  opacity: .8;
}

.e_input-container {
  position: relative;
}

.e_input-container input,
.form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px 0;
}

.e_input-container input {
  background-color: #fff;
  padding: 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.question_text {
  color: #b3642b;
  font-size: 14px;
  font-weight: 600;
}


/* MAIN */
/* =============================================== */
.f_label {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 14px 5px;
  margin: 5px 0;
  cursor: pointer;
  transition: .3s;
  width: 100%;

}



.f_radio-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.f_radio-design {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background: #CC5600;
  position: relative;
}

.f_radio-design::before {
  content: '';
  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: .3s;
}

.f_radio-input:checked+.f_radio-design::before {
  transform: scale(0);
}

.f_label-text {
  color: hsl(0, 0%, 60%);
  margin-left: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  transition: .3s;
}

.f_radio-input:checked~.f_label-text {
  color: hsl(0, 0%, 40%);
}