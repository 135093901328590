#Choose_Plan_Container .con-section {
  border: 4px solid #000000;
  padding: 28px 2px;
  border-radius: 10px;
  background-image: url('../../../../../public/stripe.png');
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;

}

#Choose_Plan_Container .con-margin-height {
  margin-top: 18vh !important;
}

#Choose_Plan_Container .con-section .con-body h2 {
  font-weight: 700;
  font-size: 36px;
}

#Choose_Plan_Container .con-section .con-body p {
  color: #292929;
  font-size: 16px;
  font-weight: 400;
}

#Choose_Plan_Container .con-section .con-body input {
  width: 1rem;
  height: 1rem;
}

#Choose_Plan_Container .con-section .con-body input:focus {
  padding: unset !important;
}

#Choose_Plan_Container .con-section .con-body .plans {
  border: 3px solid #2E2E30;
  padding: 29px 19px;
}

#Choose_Plan_Container .con-section .con-body .plans.regular {
  border-color: #2E2E30 !important;
  background-color: rgba(220, 137, 77, 1);
}

#Choose_Plan_Container .con-section .con-body .plans.plus {
  border-color: rgba(255, 183, 0, 1) !important;
  background-color: rgba(255, 248, 230, 1);

}

#Choose_Plan_Container .con-section .con-body .plans.enterprise {
  border-color: rgba(109, 109, 111, 1) !important;
  background-color: rgba(46, 46, 48, 1);
}

#Choose_Plan_Container .con-section .con-body .form-check-label {
  width: 100%;
}

#Choose_Plan_Container .con-section .con-body .plans .plans_header h4 {
  color: #0FAA4E;
  font-size: 12px;
  font-weight: 700;
}


#Choose_Plan_Container .con-section .con-body .plans .plans_content h2 {
  font-size: 24px;
  font-weight: 700;
}

#Choose_Plan_Container .con-section .con-body .plans .plans_content p {
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0;
}

#Choose_Plan_Container .con-section .con-body .plans.regular .plans_content h2,
#Choose_Plan_Container .con-section .con-body .plans.regular .plans_content p {
  color: #FEFEFE;
}

#Choose_Plan_Container .con-section .con-body .plans.plus .plans_content h2 {
  color: #000000;
}

#Choose_Plan_Container .con-section .con-body .plans.enterprise .plans_content h2 {
  color: #FFEAB3;
}

#Choose_Plan_Container .con-section .con-body .plans.enterprise .plans_content p {
  color: #E2E2E2;
}

#Choose_Plan_Container .con-section .con-body .plans input[type="radio"] {
  display: none;
}


#Choose_Plan_Container .con-section .con-body .plans .plans_content .perks h3 {
  font-size: 14px;
  font-weight: 700;
  margin: 10px 0;

}

#Choose_Plan_Container .con-section .con-body .plans.regular .plans_content .perks h3 {
  color: #FEFEFE !important;
}

#Choose_Plan_Container .con-section .con-body .plans.plus .plans_content .perks h3 {
  color: #292929 !important;

}

#Choose_Plan_Container .con-section .con-body .plans.enterprise .plans_content .perks h3 {
  color: #E2E2E2 !important;

}

#Choose_Plan_Container .con-section .con-body .plans .plans_content .perks ul li {
  font-size: 14px;
  font-weight: 400;
}

#Choose_Plan_Container .con-section .con-body .plans.regular .plans_content .perks ul li {
  color: #FAFAFA;
}


#Choose_Plan_Container .con-section .con-body .plans.plus .plans_content .perks ul li {
  color: #292929;
}

#Choose_Plan_Container .con-section .con-body .plans.enterprise .plans_content .perks ul li {
  color: #E2E2E2;
}

#Choose_Plan_Container .con-section .con-body .plans.enterprise .plans_content button:hover {
  background: #CC5600 !important;
}

/* Create a custom radio button */
#Choose_Plan_Container .con-section .con-body .plans .custom-radio {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid #c1b8b8;
  border-radius: 50%;
  cursor: pointer;
}

/* Add a circle inside the custom radio button */
#Choose_Plan_Container .con-section .con-body .plans .custom-radio::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease;
}

/* Change the color of the custom radio button when checked */
#Choose_Plan_Container .con-section .con-body .plans input[type="radio"]:checked+.custom-radio {
  border-color: #3B3D3F;
}

#Choose_Plan_Container .con-section .con-body .plans input[type="radio"]:checked+.custom-radio::after {
  background-color: #CC5600;
}

.no_plans {
  font: 26px;
  font-weight: 600;
  color: #CC5600;
}