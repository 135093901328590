/* .step_con {
    padding-right: 8rem;
} */

.video_con {
    width: 100%;
    max-width: 660px;
    gap: 5px;
    border-radius: 8px 0px 0px 0px;
    border: 10px 0px 0px 0px;

}

video {
    width: 100%;
    height: 100%;
    border: 10px solid #000000;
    height: 469px;
    border-radius: 8px;
    object-fit: contain;


}

.video_steps {
    width: 388px;
}

.video_steps h2 {
    font-size: 32px;
    font-weight: 700;
    color: #FFB700;


}

.video_steps p {
    font-size: 20px;
    font-weight: 500;
    color: #3B3D3F;

}



.easy-process .carousel-button {
    position: unset;
    transform: translateY(0);
}


.video_head_text p {

    font-size: 20px;
    font-weight: 700;
    color: #3B3D3F;

}

.video_head_text h2 {
    font-size: 64px;
    font-weight: 800;
    text-transform: uppercase;
}

.video_head_text h2 .easy {
    color: #CB5600;
}

.video_head_text h2 .yzae {
    color: #FFB700;
    font-style: italic;
}

.step_con {
    flex-wrap: wrap;
}

@media (min-width:1300px) {
    .video_head_text {
        width: fit-content;
        position: absolute;
        top: 5em;
    }

    .easy-process .carousel-buttons {
        position: absolute;
        bottom: 5em;
    }

    .easy-process .carousel_indicators {
        position: absolute;
        bottom: 10rem;
    }

}

@media (max-width:493px) {
    .video_steps {
        width: 100%;
    }
}