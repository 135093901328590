#SectionF_Main_Container {
    background-image: url('/public/img/newBg.jpeg');
    width: 100%;
    height: 268px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px;
}

#SectionF_Main_Container h2 {

    font-size: 32px;
    font-weight: 800;
    line-height: 40.32px;
    color: #272727;

}

#SectionF_Main_Container p {
    color: #272727;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.16px;
    text-align: center;

}