@import utils/scss/index

#Saved_Jobs_Main_Container

  .con-section.container-01
    padding: 40px 0

    .title, .body, .footer
      padding: 20px

    .con-context
      .title
        font-weight: 600
        font-size: 1.6rem

      .body
        display: grid
        gap: 20px

        @media (min-width: 992px)
          grid-template-columns: repeat(3, 1fr)

      .con-animated-bg
        display: grid
        gap: 14px
        padding: 20px

        @media (min-width: 992px)
          grid-template-columns: repeat(3, 1fr)

        .animated-bg
          height: 200px
          border-radius: .6rem

    .footer
      background: $dark-grey-95
