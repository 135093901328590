.conversations-container {
	background-color: #f6f6f6;
	padding-top: 30px;
	width: 100%;
}

.conversations-content {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.chat-list-container {
	flex: 1 1 100%;
}

.chat-window-container {
	flex: 1 1 100%;
}

.hide-on-mobile {
	display: none;
}

.show-on-mobile {
	display: block;
}

@media (min-width: 768px) {
	.chat-list-container {
		flex: 1 1 100%;
	}

	.chat-window-container {
		flex: 1 1 100%;
	}
}

@media (min-width: 992px) {
	.chat-list-container {
		flex: 0 0 38%;
		display: block;
	}

	.chat-window-container {
		flex: 0 0 60%;
		display: block;
	}
}