@import utils/scss/index

#SectionE_Main_Container
  .con-section
    background: $dark-grey-97
    padding: 80px 0

    .con-title
      h2
        font-weight: 700
        font-size: 1.6rem
        text-transform: uppercase
        color: #FFFFFF
      p
        font-weight: 500
        font-size: 1rem
        text-align: center
        color: #7A7A7A
        width: 90%

    .con-body
      & > ul
        gap: 6rem

        li
          img
            width: auto

  @media (min-width:992px)
    .con-section
      .con-title
        p
          width: 60%
          
      .con-body
        & > ul
          gap: 7rem
