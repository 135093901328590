.warning_con {
    font-size: 14px;
    font-weight: 400;
    color: #394452;
    background-color: #EBEBEB;
    padding: 6px 16px;
    padding-right: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;

}
.warning_con img{
    width: 24px;
}
