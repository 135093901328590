@import utils/scss/index
@import views/login/css/Login.style.sass

#Register_Main_Container
  background: $secondary-5

  .con-context
    padding: 40px 0

    .con-header
      padding: 20px 0

      h2
        font-size: 1.8rem
        font-weight: 700

        @media (min-width:992px)
          font-size: 36px
      .back
        font-size: 16px
      p
        font-size: 1.2rem
        font-weight: 400

    .con-select

      .main-selector
        cursor: pointer
        background: linear-gradient(180deg, #FFFFF1 0%, #FBF7D9 100%)
        box-shadow: 0px 2px 3px 0px #0000004D
        box-shadow: 0px 6px 10px 4px #00000026
        border-radius: 8px
        width: 100%
        overflow: hidden

        .heading

          padding: 4px 20px
          @include flex()

          h2
            font-size: 1.12rem
            color: #fff
            font-weight: 400

        .body
          position: relative
          transition: .20s
          @include flex()
      .cta
      p
        font-size: 24px
        font-weight: 700

  #Freelancer_Main_Container
    .con-title
      h1
        font-weight: 700
        font-size: 3rem
        color: #000
      p
        font-weight: 400
        font-size: 1.2rem

    @media (min-width:992px)
      .con-title
        p
          width: 70%
          text-align: center

      .form
        .heading, .title
          display: flex
          flex-direction: column
          align-items: center
          justify-content: center
