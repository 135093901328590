@import utils/scss/index

.selector
  border: 1px solid $primary-color
  color: $primary-color
  background: transparent
  padding: 10px 20px
  border-radius: 3rem
  display: inline-flex
  align-items: center
  gap: 10px
  cursor: pointer
  margin-right: 10px
  transition: .25s

  & > *
    color: $primary-color
    font-weight: 700
    font-size: 1.2rem
    white-space: nowrap

  &.active
    background: $dark-grey-95
    border-color: transparent
    color: #fff

    & > *
      color: #fff
