@import utils/scss/index

#Dashboard_Talent_Main_Container
  background: #F6F6F6

  .con-user-img
    $size-0: 140px
    $size-1: $size-0 - 10px

    .bg
      height: 77px
      width: 77px
      background: $secondary-100
      border-radius:10px
      @include flex()

      .img
        @include flex()
        background: #fff
        height: 68px
        width: 68px

  .con-profile
    cursor: pointer

    .con-user-img
      $size-0: 50px
      $size-1: $size-0 - 10px

      .bg
        height: $size-0
        width: $size-0

        .img
          height: $size-1
          width: $size-1

  .con-notify
    i
      font-size: 1.8rem

  .con-section
    padding: 40px 0

    .con-section-1
      .section-a
        background-color: white
        border-radius: 10px
        padding: 30px 20px 0

        .title
          h2
            font-weight: 600
            font-size: 1.4rem

          p
            font-size: 1.14rem

        .body
          padding: 20px 0
          overflow: auto

          .con-animated-bg
            width: 100%
            display: grid
            gap: 14px
            grid-template-columns: repeat(2, 1fr)

            .animated-bg
              height: 200px
              border-radius: .6rem

          &::-webkit-scrollbar
            height: 0
          .con-card-jod-e
            display: grid
            grid-template-columns: repeat(2 ,1fr)
            gap: 4px
            @media screen and (max-width: 768px) 
              grid-template-columns: 1fr
          .card-job
            transition: .25s
            width: 100%
            border-radius:8px
            overflow:hidden
            cursor: pointer
            box-shadow: 0px 2px 6px 2px #00000026

            

            

            &:hover
              box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 18px, rgba(0, 0, 0, 0.22) 0px 5px 5px

            .header
              background: #FEFEFE

              color: #fff
              padding: 4px 10px 
               
              .box
                $size: 20px
                height: 6px
                width: 6px
                padding: 3px
                border-radius: 50%
                border: 1px solid #000

               
              i  
               color:#000
            .context
              padding: 10px 16px
              background: #F6F6F6


              .avatar-img
                $size: 60px
                height: $size
                width: $size

              .class-1
                h2
                  font-weight: 600
                  font-size: 1.2rem
                  white-space: nowrap
                  color: $primary-color

                  i
                    font-style: normal
                    background: $primary-color
                    color: #fff
                    padding: 2px 8px
                    border-radius: 1rem
                    font-size: .8rem

                h3
                  font-weight: 600
                  font-size: 1.12rem

                h4
                  font-weight: 500
                  font-size: 1.12rem

              .class-2
                .fa-bookmark
                  font-size: 1.4rem
                  cursor: pointer

                .arrow-btn
                  padding: 10px 14px

        .footer
          padding: 30px 0
          margin: 20px 0 0
          border-top: 1px solid $dark-grey-95

      .section-b
        @extend .section-a
        background: #fffbf2
        margin: 12px 0
        padding: 20px

        @media (min-width: 992px)
          @include flex()
          gap: 10px
          justify-content: space-between

        .con-select
          select
            width: 100%
            background: url("../../../../../public/img/Vector (1).png") $dark-grey-95 no-repeat 94% !important
            color: #fff
            padding: 18px 30px 18px 14px
            border-radius: 0.4rem
            font-weight: 600

            @media (min-width: 768px)
              width: fit-content

        .con-input
          width: 100%

          input
            padding: 20px
            border: 2px solid #333
            border-radius: .25rem

        .con-submit
          justify-content: flex-end

      .section-c
        @extend .section-a
        padding: 0

        .title
          padding: 30px 20px 0

        .body
          padding: 0 20px 20px

          .con-card-job
            display: grid
            gap: 20px

            @media (min-width: 768px)
              grid-template-columns: repeat(2, 1fr)

            .card-job
              .header
                .box
                  $size: 8px
                  border: 1px solid #000
                  background: transparent !important
                  width: $size
                  height: $size
                  border-radius: 50%

              .context
                padding: 8px 12px

                .class-1
                  h2
                    font-size: 1rem
                    i
                      font-size: .7rem
                      padding: 4px 6px

        .footer
          background: $dark-grey-95
          margin: 0
          padding: 16px 0

    .con-section-2
      background-color: white
      border-radius: 10px
      padding: 30px 17px
      height: 100%
      box-shadow: 0px 1px 4px 0px #0C0C0D1A





      .con-user-dp
        display: flex
        flex-direction: column
        align-items: center
        background: #F6F6F6


        .con-user-data
          h2
            font-size: 18px
            font-weight: 700

          p
            font-size: 1rem
            font-weight: 400

          a
            font-size: 1rem
            font-weight: 700
            border-bottom: 1px solid
            padding-bottom: 0
            line-height: 1.2
            color: #FFB700
            width: fit-content
        .badge_con
          h4
           font-family: Plus Jakarta Sans
           font-size: 14px
           font-weight: 600
           line-height: 12.6px
           text-transform: uppercase


          .badge
            padding:5px 10px
           
            .badge_img
             width: 24px
             height: 24px
             img
              width:100%
              height:100%


      .sections
        .con-title
          padding-bottom: 20px
          h2
            font-size: 1.36rem
            font-weight: 600

        .section-001
          .con-body
            cursor: pointer

            .checkbox
              $size: 18px
              height: $size
              width: $size
              border: 2px solid
              border-radius: .2rem
              @include flex()

              .checker
                height: $size - 8
                width: $size - 8

                &.active
                  background: $primary-color

          p
            font-size: 1.14rem

        .section-002
          .con-body
            button
              font-size: .9rem

              @media (min-width: 992px)
                font-size: 1.1rem

        .section-003
          ul
            a
              font-weight: 700
              font-size: 1.2rem
              color: $primary-color
              text-decoration: underline
              line-height: 2.8

      .con-animated-bg
        width: 100%
        display: grid
        gap: 14px
        grid-template-columns: 1fr

        .animated-bg
          height: 200px
          border-radius: .6rem