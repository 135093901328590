@import utils/scss/index

$white-color: #ffffff

.con_modal
  background-color: rgba(0,0,0,0.4 )
  .modal
    background-color: rgba(0,0,0,0.4 )


    .modal_content
      $color: #000000
      $border: 3.55417px solid $color
      border-radius: 0
      border-bottom-right-radius:8px
      border-bottom-left-radius:8px
      border-top: 0
      border-left: 8px
      border-right: 8px
      border-bottom: 8px
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px
     
      background: $white-color

      &::-webkit-scrollbar
        height: 0

      .header
        border-radius: 0
        background: $color
        color: $white-color
        padding: 0px 7.10833px

        i:first-child
          font-size: .8rem

        i:last-child
          padding: 6px
          cursor: pointer

        h5
          font-family: 'IBM Plex Mono', monospace
          text-transform: uppercase
          text-align: center
          font-weight: 400
          font-size: .8rem

      .body
        padding: 0
