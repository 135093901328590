@import utils/scss/index

#Login_Main_Container
  background: $secondary-5

  .con-section
    $padding: 60px
    padding-top: $padding
    padding-bottom: $padding

    .con-form
      background: #FFFFFF
      box-shadow: 0px 10px 30px 0px #00000026 
      border-radius: 10px
      padding: 28px 20px

      .title
        h2
          font-weight: 700
          font-size: 1.6rem
        a
          text-decoration: underline

        p, a
          font-size: 1.1rem

  @media (min-width: 992px)

