
input{
    text-transform: capitalize;
}
.input_title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.16px;
    text-align: left;
}

.your_skills h2 {
    color: #6D6D6F;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
    text-align: left;


}

.skills_box {
    border: 1px solid #C1C1C1;
    background: #F8F8F8;
    border-radius: 4px;

}

.skills_tag {
    width: fit-content;
    /* height: 38px; */
    border-radius: 40px;
    border: 1px solid #6D6D6F;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: #6D6D6F;
    text-transform: capitalize;



}

.suggested_skills_header {


    font-size: 18px;
    font-weight: 600;


}

.skills_tag i {
    cursor: pointer;
}