.cards-b {
	display: grid;
	gap: 20px;
	padding: 20px;

	@media (min-width: 992px) {
		grid-template-columns: repeat(6, 1fr);
	}

	.card {
		border: 3px solid #000;
		cursor: pointer;

		/* Hide the default checkbox */
.checkbox-container input[type="checkbox"] {
	display: none;
}
.checkbox-container{
	margin-left: 10px;
}

/* Create a custom checkbox */
.checkbox-container label {
	display: inline-block;
	width: 15px;
	height: 15px;
	border: 2px solid #000;
	border-radius: 3px;
	background-color: #fff;
	cursor: pointer;
	position: relative;
}

/* Change background color when checked */
.checkbox-container input[type="checkbox"]:checked + label {
	background-color: #CC5600;
	padding: 2px;
}

/* Optional: Add hover effect */
.checkbox-container label:hover {
	background-color: #f0f0f0; /* Light grey background on hover */
}


		&:hover {
			box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.9);

			.img {
				filter: brightness(90%);
			}
		}

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 8px;
			background: #000;
			color: #fff;

			p,
			i {
				font-size: 0.8rem;
			}

			p {
				font-family: 'IBM Plex Mono', monospace;
			}
		}

		.Cardbody {
			display: flex;
			flex-direction: column;
			padding: 10px 12px 8px;

			.img {
				height: 130px;
				border: 0.5rem solid #ffb700;
				margin-bottom: 0.6rem;
				position: relative;

				@media (min-width: 992px) {
					height: 120px;
				}
			}

			.text {
				h1 {
					border-bottom: 1px solid #333;
					width: fit-content;
					font-size: 1.2rem;
				}

				h1.truncate-e {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					overflow: hidden;
					-webkit-line-clamp: 2;
					text-overflow: ellipsis;
				}

				h3 {
					font-size: 0.9rem;
					font-weight: 700;
				}

				p {
					font-weight: 400;
					font-size: 0.8rem;
				}

				p.truncate-e {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					overflow: hidden;
					-webkit-line-clamp: 1;
					text-overflow: ellipsis;
				}
			}
		}
	}
}
