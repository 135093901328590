@import utils/scss/index

#Onboarding_Recruiter_Main_Container
  .section
    background: $secondary-5
    padding: 60px 0

    .con-title
      h1
        font-size: 3.6rem
        font-weight: 700
      p
        font-size: 1.4rem
        font-weight: 600
        padding: 2rem 0

    .con-form
      .form
        background: #FFFFFF
        // border: 4px solid #000000
        border-radius: 1rem
        padding: 30px
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1490196078)

        .con-title
          display: flex
          align-items: center
          gap: 14px
          padding: 20px 0

          i
            font-size: 1.2rem

          h2
            font-weight: 600
            font-size: 1.6rem
