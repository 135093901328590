.radio-button-container {
    display: flex;
    align-items: center;
    gap: 24px;
}

.radio-button {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: unset !important;
    border: unset !important;
}

.radio-button__input {
    position: absolute;
    opacity: 0 !important;
    width: 0;
    height: 0;
}

.radio-button__label {
    display: inline-block;
    padding-left: 30px;
    margin-bottom: 10px;
    position: relative;

    color: #000;

    cursor: pointer;

    transition: all 0.3s ease;

    font-size: 16px !important;
    font-weight: 400;
    line-height: 22.4px;


}

.radio-button__custom {
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 2px solid #555;
    transition: all 0.3s ease;
}

.radio-button__input:checked+.radio-button__label .radio-button__custom {
    background-color: #CC5600;
    border-color: transparent;
    box-shadow: 0 0 20px #CC560080;
}

.radio-button__input:checked+.radio-button__label {
    color: bf5;
}