@import utils/scss/index

ol
  i, li
    color: $primary-color

  li
    &:not(.active) a
      text-decoration: underline
    a
      font-weight: 600
