
.s_modal{
  background-color: rgba(0,0,0,0.4 )

}
 .modal .modal_content {
  border-radius: 0;
  border-top: 0;
  border-left: 3.55417px solid #000000;
  border-right: 3.55417px solid #000000;
  border-bottom: 3.55417px solid #000000;
  box-shadow: 10px 10px 0px #000000;
  background: #ffffff;
}
 .modal .modal_content::-webkit-scrollbar {
  height: 0;
}
 .modal .modal_content .modal-header {
  border-radius: 0;
  background: #000000;
  color: #ffffff;
  padding: 0px 7.10833px;
  display: flex; 
  justify-content: space-between;
  align-items: center;
}
 .modal .modal_content .header i:first-child {
  font-size: 0.8rem;
}
 .modal .modal_content .header i:last-child {
  padding: 6px;
  cursor: pointer;
}
 .modal .modal_content .header h5 {
  font-family: 'IBM Plex Mono', monospace;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
}
 .modal .modal_content .body {
  padding: 0;
}

 .modal .modal_content .body .title{

font-family: Plus Jakarta Sans;
font-size: 36px;
font-weight: 700;
line-height: 45.36px;
text-align: left;

}