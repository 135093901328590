@import utils/scss/index

#Job_Details_Main_Container
  .con-margin-height
    padding: 30px 0

    .outline-wrap
      padding: 20px

      @media (min-width: 992px)
        padding: 30px

      @import 'views/_shared/css/post-modal/post-modal.style.sass'

      .section-a
        .header
          border: 1px solid
          margin: 30px 0
          padding: 14px

          @media (min-width: 992px)
            padding: 14px 100px 14px 14px

      .section-b, .section-c, .section-d
        border-bottom: 1px solid

      .section-d
        background-color:#F4F4F4
        padding: 15px
        border-radius: 7px
        .body
          ul
            padding: 0 20px

            li
              font-size: 1.14rem
              list-style: disc

      .con-animated-bg
        .animated-bg
          border-radius: .4rem

        &.section-1
          .animated-bg
            height: 160px

        &.section-2
          .animated-bg
            height: 60px

        &.section-3
          .animated-bg
            height: 400px
